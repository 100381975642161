import http from './http';


const base_path = '/route';


const getMenu = () => {
    return http.get(`${base_path}/getmenu?`);
}

const GetByAssociationData = (id :string) => {
    return http.get(`${base_path}/association?id=${id}`);
}

export default {
    getMenu,
    GetByAssociationData,
};