import http  from "./http";

const base_path = '/file';

const uploadFile = async (files: File[], use: string) => {
    const path = `${base_path}/upload_to_oss`;
    try {
        const res = await http.uploadFile(path, files,{use})
        return res;
    } catch (error) {
        return Promise.reject(error);
    }
}

export default {
    uploadFile
}

